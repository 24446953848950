export const XIC = 'XIC';
export const XIO = 'XIO';
export const OSP = 'OSP';
export const OSN = 'OSN';
export const OTE = 'OTE';
export const OTL = 'OTL';
export const OTU = 'OTU';
export const OTN = 'OTN';
export const CTD = 'CTD';
export const CTU = 'CTU';
export const CTUD = 'CTUD';
export const TOF = 'TOF';
export const TON = 'TON';
export const TONR = 'TONR';
export const ADD = 'ADD';
export const SUB = 'SUB';
export const MUL = 'MUL';
export const DIV = 'DIV';
export const MOV = 'MOV';
export const MOVE = 'MOVE';
export const EQU = 'EQU';
export const NEQ = 'NEQ';
export const GRT = 'GRT';
export const GEQ = 'GEQ';
export const LES = 'LES';
export const LEQ = 'LEQ';
